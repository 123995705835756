import {
  updateResourceState,
  createResourceReducer,
  RESOURCE
} from 'react-structure-admin';
import {
  INVOICE,
  INVOICE_REFRESH,
  INVOICE_OPEN_CANCEL,
  INVOICE_CLOSE_CANCEL,
  INVOICE_CANCEL,
  INVOICE_CANCEL_FINISHED,
  INVOICE_OPEN_EVENTS,
  INVOICE_CLOSE_EVENTS,
  INVOICE_GET_EVENTS,
  INVOICE_GET_EVENTS_FINISHED,
  INVOICE_OPEN_REMOVE,
  INVOICE_CLOSE_REMOVE,
  INVOICE_REMOVE,
  INVOICE_REMOVE_FINISHED,
  INVOICE_CREATE_DISABLEMENT,
  INVOICE_CREATE_DISABLEMENT_FINISHED,
  INVOICE_REFRESH_DISABLEMENT,
  INVOICE_OPEN_CORRECTION,
  INVOICE_CLOSE_CORRECTION,
  INVOICE_CORRECTION,
  INVOICE_CORRECTION_FINISHED,
  INVOICE_OPEN_ISSUE,
  INVOICE_CLOSE_ISSUE,
  INVOICE_ISSUE,
  INVOICE_ISSUE_FINISHED
} from './invoiceActions';

const invoiceReducer = createResourceReducer(
  INVOICE,
  [],
  {
    [INVOICE_OPEN_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        invoice: action.payload,
        activeModal: INVOICE_OPEN_CANCEL
      }));
    },

    [INVOICE_REFRESH](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [INVOICE_CLOSE_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [INVOICE_CANCEL](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [INVOICE_CANCEL_FINISHED](state, action) {
      if (state.orders) {
        state.orders = { ...state.orders, loaded: false };
      }
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        loading: false,
        loaded: false,
        invoice: null
      }));
    },

    [INVOICE_OPEN_EVENTS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        invoice: action.payload,
        queryParams: { id: action.payload.id, number: action.payload.number },
        activeModal: INVOICE_OPEN_EVENTS
      }));
    },

    [INVOICE_CLOSE_EVENTS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        queryParams: null,
        events: []
      }));
    },

    [INVOICE_GET_EVENTS](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        invoice: action.payload,
        loadingEvents: true,
        activeModal: INVOICE_OPEN_EVENTS
      }));
    },

    [INVOICE_GET_EVENTS_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        events: action.payload.data,
        loadingEvents: false
      }));
    },

    [INVOICE_OPEN_REMOVE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        data: action.payload,
        activeModal: INVOICE_OPEN_REMOVE
      }));
    },

    [INVOICE_CLOSE_REMOVE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [INVOICE_REMOVE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [INVOICE_REMOVE_FINISHED](state, action) {
      if (state.orders) {
        state.orders = { ...state.orders, loaded: false };
      }
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        loading: false,
        loaded: false,
        invoice: null
      }));
    },

    [INVOICE_CREATE_DISABLEMENT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [INVOICE_CREATE_DISABLEMENT_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: false,
        loaded: false
      }));
    },

    [INVOICE_REFRESH_DISABLEMENT](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loaded: false
      }));
    },

    [INVOICE_OPEN_CORRECTION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        id: action.payload,
        activeModal: INVOICE_OPEN_CORRECTION
      }));
    },

    [INVOICE_CLOSE_CORRECTION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [INVOICE_CORRECTION](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true,
        loaded: true
      }));
    },

    [INVOICE_CORRECTION_FINISHED](state, action) {
      if (state.orders) {
        state.orders = { ...state.orders, loaded: false };
      }
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        loading: false,
        loaded: false,
        id: null
      }));
    },

    [INVOICE_OPEN_ISSUE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        id: action.payload,
        activeModal: INVOICE_OPEN_ISSUE
      }));
    },

    [INVOICE_CLOSE_ISSUE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null
      }));
    },

    [INVOICE_ISSUE](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        loading: true
      }));
    },

    [INVOICE_ISSUE_FINISHED](state, action) {
      return updateResourceState(state, action, RESOURCE, () => ({
        activeModal: null,
        loading: false,
        loaded: false
      }));
    }
  },

  (state, action) => {
    if (state.orders) {
      state.orders = { ...state.orders, loaded: false };
    }
    return updateResourceState(state, action, RESOURCE, () => ({
      loading: false,
      loadingEvents: false
    }));
  }
);

export default invoiceReducer;
